export const locations = [
  {
    id: 1,
    name: 'Aeroporto de Guarulhos',
    address: 'Rod. Hélio Smidt, S/N - Guarulhos',
    state: 'SP',
    city: 'São Paulo',
    cep: '07190-100',
    phone: '(11) 91317-8651',
    extraPhone: '(11) 91318-4327',
    lat: '-23.45003605394691',
    lng: '-46.48900267455391',
  },
  {
    id: 2,
    name: 'Aeroporto de Congonhas',
    address: 'Av. Washington Luís, S/N - Vila Congonhas',
    state: 'SP',
    city: 'São Paulo',
    cep: '04626-911',
    phone: '(11) 98554-4040',
    lat: '-23.62884838005008',
    lng: '-46.66051426105505',
  },
  {
    id: 3,
    name: 'Aeroporto de Viracopos',
    address: 'Rod. Santos Dumont, KM 66',
    state: 'SP',
    city: 'Campinas',
    cep: '13055-900',
    phone: '(11) 98554-4040',
    lat: '-23.016154707312424',
    lng: '-47.14174506107267',
  },
  {
    id: 4,
    name: 'Aeroporto de Salvador',
    address: 'Pr. Gago Coutinho, S/N - São Cristóvão',
    state: 'BA',
    city: 'Salvador',
    cep: '41510-045',
    phone: '(11) 91318-1002',
    lat: '-12.913261995003383',
    lng: '-38.337192461310316',
  },
  {
    id: 5,
    name: 'Aeroporto de Florianópolis',
    address: 'Rod. Ac. ao Aeroporto, 6.200 - Carianos',
    state: 'SC',
    city: 'Florianópolis',
    cep: '88047-902',
    phone: '(48) 98469-3499',
    lat: '-27.67412934500861',
    lng: '-48.54667946092865',
  },
  {
    id: 6,
    name: 'Aeroporto de Cuiabá',
    address: 'Av. João Ponce de Arruda, S/N - Vila Pirineu',
    state: 'MT',
    city: 'Várzea Grande',
    cep: '78110-900',
    phone: '(67) 99204-9004',
    lat: '-15.650859495146188',
    lng: '-56.1202701612564',
  },
  {
    id: 7,
    name: 'Aeroporto de Recife',
    address: 'Praça Min. Salgado Filho, SN - Imbiribeira, Recife',
    state: 'PE',
    city: 'Recife',
    cep: '51210-902',
    phone: '(81) 98557-0500',
    lat: '-8.134166648636077',
    lng: '-34.917235176727',
  },
  {
    id: 8,
    name: 'Aeroporto do Galeão',
    address: 'Av. Vinte de Janeiro, S/N - Ilha do Governador',
    state: 'RJ',
    city: 'Rio de Janeiro',
    cep: '21941-900',
    phone: '(21) 99384-8230',
    lat: '-22.81600220297665',
    lng: '-43.23820760340743',
  },
  {
    id: 9,
    name: 'Aeroporto Santos Drumont',
    address: 'Praça Sen. Salgado Filho, S/N - Centro',
    state: 'RJ',
    city: 'Rio de Janeiro',
    cep: '20021-340',
    phone: '(21) 99384-8230',
    lat: '-22.91227402638116',
    lng: '-43.16764764573395',
  },
  {
    id: 10,
    name: 'Rodoviária Jabaquara',
    address: 'R. dos Jequitibás, S/N - Jabaquara',
    state: 'SP',
    city: 'São Paulo',
    cep: '04321-090',
    phone: '(11)98554-4040',
    lat: '-23.646130689977163',
    lng: '-46.64236990338358',
  },
  {
    id: 11,
    name: 'Rodoviária Barra Funda',
    address: 'R. Jorn. Aloysio Biondi, S/N - Barra Funda',
    state: 'SP',
    city: 'São Paulo',
    cep: '01154-060',
    phone: '(11)98554-4040',
    lat: '-23.524438398592206',
    lng: '-46.66717493222236',
  },
  {
    id: 12,
    name: 'Rodoviária Sorocaba',
    address: 'Av. Comendador Pereira Inácio, 100 - Jardim Vergueiro',
    state: 'SP',
    city: 'Sorocaba',
    cep: '18030-005',
    phone: '(15) 99654-2499',
    lat: '-23.50691855570158',
    lng: '-47.45591304571675',
  },
  {
    id: 13,
    name: 'Rodoviária Curitiba',
    address: 'Av. Presidente Affonso Camargo, 330 - Jardim Botânico',
    state: 'PR',
    city: 'Curitiba',
    cep: '80060-980',
    phone: '(11) 99487-9891',
    lat: '-25.437591658216235',
    lng: '-49.25780281682341',
  },
];
