import React from 'react';
import './HeroHome.css';
import { Link } from 'react-router-dom';

const HeroHome = () => {
  return (
    <div className="hero-home">
      <div className="hero-home-overlay">
        <div className="hero-home-content">
          <p className="title">Muito além de guarda volumes</p>
          <Link to="/contact" className="cta-button">
            Faça sua cotação
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeroHome;
