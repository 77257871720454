import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import PartnerCard from './PartnerCard';
import './PartnersSection.css';

const PartnersSection = () => {
  const carrossel = useRef();
  const [carrosselWith, setCarrosselWidth] = useState(0);

  useEffect(() => {
    // set how long can the carrossel scroll
    setCarrosselWidth(
      carrossel.current?.scrollWidth - carrossel.current?.offsetWidth
    );
  }, []);

  const partners = [
    {
      id: 1,
      logo: './mysimtravel.png',
      name: 'mysimtravel',
      description:
        'Com nossa parceria com o chip Internacional mysimtravel, você terá um consumo inteligente em viagens para o exterior, a lazer ou negócios, sem altos custos com telefonia ou dados.',
      linkText: 'Saiba mais sobre a mysimtravel',
      lunkUrl: 'https://mystore.mysimtravel.com/mysimtravelgrut3',
    },
    {
      id: 2,
      logo: './dhlexpress.png',
      name: 'DHL',
      description:
        'Com a DHL, temos uma ampla variedade de serviços de envio expressos para seus documentos e encomendas, além de soluções de preparo e acompanhamento de acordo com sua necessidade.',
      linkText: 'Saiba mais sobre a DHL',
      lunkUrl: 'https://www.dhl.com/br-pt/home/express.html',
    },
  ];

  return (
    <div className="partners-section">
      <h2>Oferecendo o melhor com nossos parceiros</h2>
      <motion.div
        ref={carrossel}
        className="partners-carrossel"
        whileTap={{ cursor: 'grabbing' }}
      >
        <motion.div
          className="partners-inner-carrossel"
          drag="x"
          dragConstraints={{ right: 0, left: -carrosselWith }}
        >
          {partners.map((partner) => (
            <PartnerCard
              key={partner.id}
              logo={partner.logo}
              name={partner.name}
              description={partner.description}
              linkText={partner.linkText}
              linkUrl={partner.lunkUrl}
            />
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default PartnersSection;
