import React from "react";
import "./Navbar.css";

import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo-secondary.svg";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <ul className="navbar-links">
        <Link to="/about">
          <li>Empresa</li>
        </Link>
        <Link to="/services">
          <li>Serviços</li>
        </Link>
        <Link to="/locations">
          <li>Unidades</li>
        </Link>
        <Link to="/lockers">
          <li>Guarda Volume</li>
        </Link>
        <Link to="/DHL">
          <li>DHL Express</li>
        </Link>
        <Link to="/contact">
          <li>Contato</li>
        </Link>
      </ul>
    </nav>
  );
};

export default Navbar;
