import React from "react";
import "./DHLContent.css";

const DHLContent = () => {
  return (
    <div className="dhl-content">
      <div className="dhl-content-section">
        <h2>Entregas com profissionalismo e segurança</h2>
        <p>
          A DHL conecta pessoas de mais de 220 países e territórios em todo o
          mundo.
        </p>
        <br />
        <p>
          Com os serviços de entrega DHL Express para envios nacionais e
          internacionais você conta com especialistas em transporte, remessas
          courier e entregas expressas.
        </p>
        <br />
        <p>
          A Malex do Brasil é agente autorizado de envios DHL e dispõe de uma
          ampla variedade de serviços expressos para seus documentos e
          encomendas, além de soluções de preparo e acompanhamento de seus
          envios, de acordo com sua necessidade. Saiba tudo o que a Malex e a
          DHL Express pode fazer por você!
        </p>
        <a
          href="https://www.dhl.com/br-pt/home/express.html"
          target="_blank"
          rel="noreferrer"
        >
          Saiba mais sobre a DHL
        </a>
      </div>
      <img src="./dhl-content.png" alt="Entregas DHL" />
    </div>
  );
};

export default DHLContent;
