import React from 'react';
import './ServicesContent.css';
import ServiceItem from './ServiceItem';

const ServicesContent = () => {
  const services = [
    {
      imageSrc: './services-lockers.jpg',
      title: 'Guarda-Volumes',
      description:
        'De maneira geral, hoje oferecemos para o seu ponto de venda, a instalação de guarda-volumes de uma maneira inovadora, fazendo com que o seu negócio seja reconhecido pelo diferencial que ele proporciona.\n\nNosso conceito prático, ocupa pouco espaço, além do que pode ser instalado em vários espaços, isso ocorre, pois nosso design é modular e seguro.',
      linkText: 'Conheça nossos guarda volumes',
      linkUrl: '/lockers',
    },
    {
      imageSrc: './services-dhl.jpg',
      title: 'Remessas Expressas DHL',
      description:
        'A Malex do Brasil é agente autorizado da DHL e dispõe de uma ampla variedade de serviços expressos.\n\nCom os serviços de entrega DHL Express para envios nacionais e internacionais você conta com especialistas em transporte, remessas courier e entregas expressas.',
      linkText: 'Saiba como a DHL pode te ajudar',
      linkUrl: 'https://www.dhl.com/br-pt/home/express.html',
    },
    {
      imageSrc: './services-take-in-out.jpg',
      title: 'Entrega & Retira',
      description:
        'Utilize nossos serviços de entrega de produtos, documentos ou quaisquer outros objetos. Entregue suas encomendas em nossas lojas 24 horas por dia, 7 dias por semana sem riscos de falhas na entrega por cliente ausente, a encomenda pode ser retirada no melhor dia e horário.\n\nEconomia para seu cliente e agilidade para seu negócio.',
      linkText: '',
      linkUrl: '',
    },
    {
      imageSrc: './services-locker-rent.jpg',
      title: 'Locações de Armários',
      description:
        'Já as locações de armários para eventos são as soluções ideais para garantir a segurança dos itens do seu público, fora que o fato de não precisar carregar volumes pesados e inconvenientes já é um detalhe que muitos clientes irão valorizar.\n\nProteja sua bagagem de forma rápida e segura!',
      linkText: '',
      linkUrl: '',
    },
    {
      imageSrc: './services-marketing.jpg',
      title: 'Malex Publicidade',
      description:
        'A Malex Publicidade possui disponibilidade de proporcionar uma parceria em seletos locais como ótima forma de divulgar a sua marca através da frente dos armários. Uma forma inovadora de mídia.',
      linkText: '',
      linkUrl: '',
    },
    {
      imageSrc: './services-mysimtravel.jpg',
      title: 'Chip Internacional',
      description:
        'Com nossa parceria com o chip Internacional mysimtravel você terá um consumo inteligente em viagens para o exterior, a lazer ou negócios, sem altos custos com telefonia ou dados.\n\nA mysimtravel oferece SIM Cards (chips) com pacotes de dados internacionais e você poderá conectar-se aos seus familiares ou colegas de trabalho com baixo custo e sem necessidade de ter que adquirir um chip no exterior. Você já sai com a facilidade diretamente do Brasil.',
      linkText: 'Saiba mais no site da mysimtravel',
      linkUrl: 'https://mystore.mysimtravel.com/mysimtravelgrut3',
    },
  ];
  return (
    <div className="services-content">
      <div className="services-content-grid">
        {services.map((service, index) => (
          <ServiceItem
            key={index}
            imageSrc={service.imageSrc}
            title={service.title}
            description={service.description}
            linkText={service.linkText}
            linkUrl={service.linkUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default ServicesContent;
