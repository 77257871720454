import React from "react";
import "./HeroLockers.css";

const HeroLockers = () => {
  return (
    <div className="hero-lockers">
      <h2>Veja os modelos e as vantagens dos nossos guarda-volumes</h2>
    </div>
  );
};

export default HeroLockers;
