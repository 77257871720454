import React from 'react';
import {
  AltNavbar,
  ContactSection,
  Footer,
  HeroLockers,
  LockersContent,
  NavbarMobile,
} from '../components';
import { isMobile } from 'react-device-detect';

const Lockers = () => {
  return (
    <>
      {isMobile ? <NavbarMobile /> : <AltNavbar />}
      {isMobile ? <div style={{ paddingTop: 86.5 }} /> : <></>}
      <HeroLockers />
      <LockersContent />
      <ContactSection />
      <Footer />
    </>
  );
};

export default Lockers;
