import React from "react";
import { Link } from "react-router-dom";
import "./LocationSection.css";

const LocationSection = () => {
  return (
    <div className="location-section">
      <div
        className="location-img"
        alt="Localize o ponto Malex mais próximo de você"
      />
      <div className="location-section-content">
        <h2>Estamos sempre perto de você</h2>
        <p>
          Com segurança comprovada, praticidade e lucratividade, possuímos mais
          de 100 instalações em todo o território nacional.
        </p>
        <br />
        <p>
          Procure em seu Estado e encontre o local mais próximo para utilizar os
          serviços da Malex.
        </p>
        <Link to="/locations" className="location-section-button">
          Conheça nossas unidades
        </Link>
      </div>
    </div>
  );
};

export default LocationSection;
