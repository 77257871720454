import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './NavbarMobile.css';
import { ReactComponent as Logo } from '../assets/logo-primary.svg';

const NavbarMobile = () => {
  const [open, setOpen] = useState(false);

  const toggleMenu = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.type === 'Tab' || event.type === 'Shift')
    ) {
      return;
    }
    if (open) {
      document.getElementById('root').style.overflow = 'hidden';
    } else {
      document.getElementById('root').style.overflow = 'scroll';
    }
    setOpen(open);
  };

  return (
    <nav className="navbar-mobile">
      <div className="navbar-mobile-logo">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <ul className={`navbar-mobile-links ${open ? 'open' : ''}`}>
        <Link to="/about">
          <li>Empresa</li>
        </Link>
        <Link to="/services">
          <li>Serviços</li>
        </Link>
        <Link to="/locations">
          <li>Unidades</li>
        </Link>
        <Link to="/lockers">
          <li>Guarda Volume</li>
        </Link>
        <Link to="/DHL">
          <li>DHL Express</li>
        </Link>
        <Link to="/contact">
          <li>Contato</li>
        </Link>
      </ul>
      <button
        onClick={toggleMenu(!open)}
        className={`burger-button ${open ? 'open' : ''}`}
      >
        <div></div>
        <div></div>
        <div></div>
      </button>
    </nav>
  );
};

export default NavbarMobile;
