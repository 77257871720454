import React from "react";
import "./HeroLocation.css";

const HeroLocation = () => {
  return (
    <div className="hero-location">
      <h2>Encontre um de nossos representantes mais próximo de você</h2>
    </div>
  );
};

export default HeroLocation;
