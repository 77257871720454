import React from "react";
import "./AboutContent.css";

const AboutContent = () => {
  return (
    <div className="about-content">
      <h2>Há mais de 40 anos trabalhando para oferecer o melhor para você</h2>
      <p>
        Fundada em Junho de 1976, a Malex do Brasil foi se desenvolvendo e
        aprimorando a maneira de oferecer serviços com base na sua missão de
        proporcionar sempre qualidade e inovação.
      </p>
      <br />
      <p>
        O começo dessa longa trajetória foi com a primeira unidade no aeroporto
        de Manaus e depois de muitas conquistas nos tornamos pioneiros em
        realizar operação de guarda-volumes para os principais aeroportos,
        rodoviárias, eventos e pontos do país. A locação e venda de
        guarda-volumes até os dias de hoje nos proporciona uma análise mais
        apurada dos desejos e das necessidades do nosso público.
      </p>
      <br />
      <p>
        Em linha com essa demanda do mercado, a empresa diversificou as
        atividades e os serviços e atualmente contamos com o serviço de remessa
        courier, serviços gráficos, serviços de entrega e retirada, além de
        disponibilizar publicidade nos armários e entrega de chips
        internacionais da mysimtravel.
      </p>
      <br />
      <p>
        São mais de 40 anos operando no Brasil, com mais de 100 instalações em
        todo o território nacional e com comprovada segurança, praticidade e
        lucratividade.
      </p>
      <div
        className="about-content-img"
        alt="Guarda volumes da Malex em evento"
      />
    </div>
  );
};

export default AboutContent;
