import React from 'react';
import { AltNavbar, ContactContent, Footer, NavbarMobile } from '../components';
import { isMobile } from 'react-device-detect';

const Contact = () => {
  return (
    <>
      {isMobile ? <NavbarMobile /> : <AltNavbar />}
      {isMobile ? <div style={{ paddingTop: 86.5 }} /> : <></>}
      <ContactContent />
      <Footer />
    </>
  );
};

export default Contact;
