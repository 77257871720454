import React from 'react';
import {
  AboutContent,
  AltNavbar,
  ContactSection,
  Footer,
  HeroAbout,
  NavbarMobile,
} from '../components';
import { isMobile } from 'react-device-detect';

const About = () => {
  return (
    <>
      {isMobile ? <NavbarMobile /> : <AltNavbar />}
      {isMobile ? <div style={{ paddingTop: 86.5 }} /> : <></>}
      <HeroAbout />
      <AboutContent />
      <ContactSection />
      <Footer />
    </>
  );
};

export default About;
