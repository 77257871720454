import React from "react";
import { FaRegEnvelope } from "react-icons/fa";
import { MdOutlinePhone } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import "./Footer.css";

import { ReactComponent as Logo } from "../assets/logo-primary.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <Logo />
        <div className="footer-contact">
          <a href="mailto:contato@malex.com.br">
            <p>
              <FaRegEnvelope className="icon" /> contato@malex.com.br
            </p>
          </a>
          <a href="tel:+551142368888">
            <p>
              <MdOutlinePhone className="icon" /> (11) 4236-8888
            </p>
          </a>
          <a
            href="https://wa.me/5511985544040"
            target="_blank"
            rel="noreferrer"
          >
            <p>
              <IoLogoWhatsapp className="icon whatsapp" /> (11) 98554-4040
            </p>
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>©2024 | Todos os direitos Reservados à Malex</p>
      </div>
    </footer>
  );
};

export default Footer;
