import React from "react";
import "./AltNavbar.css";

import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo-primary.svg";
import Breadcrumb from "./Breadcrumb";

const AltNavbar = () => {
  return (
    <>
      <nav className="alt-navbar">
        <div className="alt-navbar-logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <ul className="alt-navbar-links">
          <Link to="/about">
            <li>Empresa</li>
          </Link>
          <Link to="/services">
            <li>Serviços</li>
          </Link>
          <Link to="/locations">
            <li>Unidades</li>
          </Link>
          <Link to="/lockers">
            <li>Guarda Volume</li>
          </Link>
          <Link to="/DHL">
            <li>DHL Express</li>
          </Link>
          <Link to="/contact">
            <li>Contato</li>
          </Link>
        </ul>
      </nav>
      <Breadcrumb />
    </>
  );
};

export default AltNavbar;
