import React from "react";
import { Link } from "react-router-dom";
import ServiceCard from "./ServiceCard";
import "./ServiceSection.css";

// Icons can be local images or URLs
const services = [
  {
    icon: "./safe-lock.png", // Replace with actual icon URLs or paths
    title: "Guarda Volumes",
  },
  {
    icon: "./locker.png",
    title: "Locação de armários",
  },
  {
    icon: "./sim-card.png",
    title: "Chip Internacional",
  },
  {
    icon: "./fast-ship.png",
    title: "Envio de remessas",
  },
  {
    icon: "./marketing.png",
    title: "Publicidade em armários",
  },
  /*  {
    icon: "icon-url-6",
    title: "Entrega e retirada",
  },*/
];

const ServiceSection = () => {
  return (
    <div className="service-section">
      <h2>A Malex tem ótimas soluções para a logística da sua empresa</h2>
      <div className="service-cards-container">
        {services.map((service, index) => (
          <ServiceCard key={index} icon={service.icon} title={service.title} />
        ))}
      </div>
      <Link className="cta-button" to="/services">
        Confira nossos serviços
      </Link>
    </div>
  );
};

export default ServiceSection;
