import React, { useRef, useState } from "react";
import "./ContactContent.css";
import validateEmail from "../utils/useValidadeEmail";

const ContactContent = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const emaiInputlRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submit", formData);
    if (!validateEmail(formData.email)) {
      alert("Insira um e-mail válido");
      emaiInputlRef.current.focus();
      return;
    }
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });

    alert("E-mail enviado!\nAguarde nosso retorno.");
  };

  return (
    <div className="contact-content">
      <div className="form-section">
        <h2>Fale conosco</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nome</label>
            <input
              id="name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="João Souza"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">E-mail</label>
            <input
              id="email"
              type="text"
              name="email"
              ref={emaiInputlRef}
              value={formData.email}
              onChange={handleChange}
              placeholder="joao.souza@gmail.com"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Assunto</label>
            <input
              id="subject"
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder="Assunto do E-mail"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Mensagem</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="..."
              required
            />
          </div>
          <button type="submit">Enviar</button>
        </form>
      </div>
      <div className="info-section">
        <h2>Onde estamos</h2>
        <iframe
          title="location-map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.4272670509777!2d-46.66232772486309!3d-23.588239759628672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5a8b09a3b2e1%3A0xb56f6daba9f2a0bb!2sRua%20Ot%C3%A1vio%20Tarqu%C3%ADnio%20de%20Souza%2C%20328%20-%20Vila%20Congonhas%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004613-000%2C%20Brazil!5e0!3m2!1sen!2sus!4v1646284461031!5m2!1sen!2sus"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
        />
        <p>
          <strong>Endereço:</strong> Rua Otávio Tarquínio de Souza, 328 - São
          Paulo, SP <br />
          <strong>CEP:</strong> 04613-000 <br />
          <strong>Atendimento:</strong> segunda a sexta, das 9h às 18h, exceto
          feriados. <br />
          <strong>E-mail:</strong> contato@malex.com.br <br />
          <strong>Tel:</strong> (11) 4236-8888 <br />
          <strong>WhatsApp:</strong> (11) 98554-4040
        </p>
      </div>
    </div>
  );
};

export default ContactContent;
