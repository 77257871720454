export default function transformPathName(pathname) {
  switch (pathname) {
    case "/about":
      return "Sobre a empresa";
    case "/services":
      return "Serviços";
    case "/lockers":
      return "Guarda Volumes";
    case "/DHL":
      return "DHL";
    case "/locations":
      return "Onde Encontrar";
    case "/contact":
      return "Contato";
    default:
      return pathname;
  }
}
