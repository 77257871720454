import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import { CiLocationOn } from 'react-icons/ci';
import { isMobile, deviceDetect } from 'react-device-detect';

import './LocationContent.css';
import { locations } from '../utils/useLocations';
import { FaWhatsapp } from 'react-icons/fa';

// Modal style
const style = {
  position: 'absolute',
  bottom: '1%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 320,
  borderRadius: 4,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const LocationContent = () => {
  const [selectedState, setSelectedState] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLat, setSelectedLat] = useState('');
  const [selectedLng, setSelectedLng] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const device = deviceDetect();

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const filterPhoneNumber = (phoneNumber) => {
    const filteredPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
    const locatedPhoneNumber = '+55' + filteredPhoneNumber;

    return locatedPhoneNumber;
  };

  const filteredLocations = selectedState
    ? locations.filter((location) => location.state === selectedState)
    : locations;

  const handleLocationLink = (lat, lng, name) => {
    setSelectedLat(lat);
    setSelectedLng(lng);
    setSelectedName(name);
    isMobile ? setIsModalOpen(true) : openGoogleMaps(name);
  };

  const openGoogleMaps = (name) => {
    const googleMapsUrl = `https://www.google.com/maps?q=${name}`;
    window.open(googleMapsUrl, '_blank');
    setIsModalOpen(false);
  };
  const openAppleMaps = (lat, lng) => {
    const appleMapsUrl = `https://maps.apple.com/?q=${lat},${lng}`;
    window.open(appleMapsUrl, '_blank');
    setIsModalOpen(false);
  };
  const openWazeleMaps = (lat, lng) => {
    const wazeleMapsUrl = `https://waze.com/ul?ll=${lat},${lng}`;
    window.open(wazeleMapsUrl, '_blank');
    setIsModalOpen(false);
  };

  return (
    <div className="location-content">
      <h2>Filtre por estado</h2>
      <select value={selectedState || ''} onChange={handleStateChange}>
        <option value="">Selecione o Estado</option>
        <option value="SP">São Paulo</option>
        <option value="RJ">Rio de Janeiro</option>
        <option value="BA">Bahia</option>
        <option value="MT">Mato Grosso</option>
        <option value="PE">Pernambuco</option>
        <option value="PR">Paraná</option>
        <option value="SC">Santa Catarina</option>
      </select>
      <div className="locations-grid">
        {filteredLocations.map((location) => (
          <div key={location.id} className="location-card">
            <h3>{location.name}</h3>
            <div className="location-info">
              <CiLocationOn className="icon" size={24} />
              <div>
                <button
                  onClick={() =>
                    handleLocationLink(
                      location.lat,
                      location.lng,
                      location.name
                    )
                  }
                >
                  <p>{location.address}</p>
                </button>
                <p>
                  {location.city} - {location.state}
                </p>
                <p>CEP: {location.cep}</p>
              </div>
            </div>
            <div className="location-info location-phone">
              <FaWhatsapp className="icon" size={18} />
              <div>
                <a
                  href={`https://wa.me/${filterPhoneNumber(location.phone)}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>{location.phone}</p>
                </a>
                {location.extraPhone && (
                  <a
                    href={`https://wa.me/${filterPhoneNumber(
                      location.extraPhone
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p>{location.extraPhone}</p>
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {isMobile && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {device.os === 'iOS' && (
              <>
                <p className="modal-text">
                  <button
                    onClick={() => openAppleMaps(selectedLat, selectedLng)}
                  >
                    Abrir com Mapas
                  </button>
                </p>
                <br />
              </>
            )}
            <p className="modal-text">
              <button onClick={() => openGoogleMaps(selectedName)}>
                Abrir com Google Maps
              </button>
            </p>
            <br />
            <p className="modal-text">
              <button onClick={() => openWazeleMaps(selectedLat, selectedLng)}>
                Abrir com Waze
              </button>
            </p>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default LocationContent;
