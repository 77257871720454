import React from 'react';
import './DescriptionSection.css';

const DescriptionSection = () => {
  return (
    <div className="description-section">
      <div className="description-img" alt="Clientes fazendo uso dos lockers" />
      <div className="description-content">
        <h2>Atendendo todas as suas necessidades</h2>
        <p>
          A Malex proporciona muito mais que o simples serviços. Nosso objetivo
          é auxiliar na criação de novas experiências que tragam reconhecimento
          pela qualidade, seriedade e segurança no sucesso do seu negócio.
        </p>
        <br />
        <p>
          Com qualidade e praticidade nossos produtos transformaram a rotina dos
          consumidores.
          <br />
          Nossos parceiros nos seus pontos de vendas, se tornaram referência em
          proporcionar segurança, praticidade e conforto.
        </p>
      </div>
    </div>
  );
};

export default DescriptionSection;
