import React from 'react';
import './ServiceItem.css';
import { Link } from 'react-router-dom';

const ServiceItem = ({ imageSrc, title, description, linkText, linkUrl }) => {
  return (
    <div className="service-item">
      <img src={imageSrc} alt={title} className="service-item-image" />
      <h3 className="service-item-title">{title}</h3>
      <p className="service-item-description">{description}</p>
      {linkUrl.includes('dhl') || linkUrl.includes('mysimtravel') ? (
        <a
          target="_blank"
          href={linkUrl}
          className="service-item-link"
          rel="noreferrer"
        >
          {linkText}
        </a>
      ) : (
        <Link to={linkUrl} className="service-item-link">
          {linkText}
        </Link>
      )}
    </div>
  );
};

export default ServiceItem;
