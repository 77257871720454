import React from "react";
import { Link } from "react-router-dom";
import "./ContactSection.css";

const ContactSection = () => {
  return (
    <div className="contact-section">
      <div className="contact-section-content">
        <h2>Entre em contato conosco</h2>
        <p>
          Venha conhecer nossos serviços e tecnologias, contamos com uma equipe
          de profissionais preparados para oferecer a melhor solução para ajudar
          o seu negócio.
        </p>
        <Link to="/contact" className="contact-button">
          Faça sua cotação
        </Link>
      </div>
    </div>
  );
};

export default ContactSection;
