import React from "react";
import "./ServiceCard.css";

const ServiceCard = ({ icon, title }) => {
  return (
    <div className="service-card">
      <div className="icon-circle">
        <img draggable="false" src={icon} alt={title} />
      </div>
      <p>{title}</p>
    </div>
  );
};

export default ServiceCard;
