import React from "react";
import { motion } from "framer-motion";
import "./PartnerCard.css";

const PartnerCard = ({ logo, name, description, linkText, lunkUrl }) => {
  return (
    <motion.div className="partner-card">
      <img
        draggable="false"
        src={logo}
        alt={`${name} logo`}
        className="partner-logo"
      />
      <h3>{name}</h3>
      <p>{description}</p>
      <a
        target="_blank"
        href={lunkUrl}
        rel="noreferrer"
        className="partner-link"
      >
        {linkText}
      </a>
    </motion.div>
  );
};

export default PartnerCard;
