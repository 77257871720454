import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import "./Breadcrumb.css";

import useTranslatePathname from "../utils/useTranslatePathname";

const Breadcrumb = () => {
  const location = useLocation();
  const { pathname } = location;
  const pathbameTranslated = useTranslatePathname(pathname);

  return (
    <div className="breadcrumb">
      <p>
        <Link style={{ color: "inherit", textDecoration: "inherit" }} to="/">
          Home
        </Link>{" "}
        <FaArrowRight className="arrow" /> {pathbameTranslated}
      </p>
    </div>
  );
};

export default Breadcrumb;
