import React from "react";
import "./LockersContent.css";

const LockersContent = () => {
  return (
    <div className="lockers-content">
      <div className="lockers-description-section">
        <div className="lockers-description">
          <img
            draggable="false"
            src="./digital-locker-1.jpg"
            alt="Armários digitais Malex"
          />
          <div className="lockers-description-text">
            <h2>Atendendo as necessidades do seu negócio</h2>
            <p>
              Devido ao cotidiano agitado da população, é cada vez mais comum
              percebermos que algumas novas necessidades estão surgindo, e foi
              justamente pensando em atender um nicho, que hoje a Malex traz em
              seus Guarda-Volumes segurança e praticidade.
            </p>
            <br />
            <p>
              O dia a dia de um aeroporto, rodoviária ou evento não é nada calmo
              e organizado, mas que tal proporcionar segurança e praticidade
              diante do caos? Para quem está no aeroporto ou rodoviária e não
              quer a inconveniência de carregar volumes ou deixar as malas
              desatendidas por segurança durante uma refeição ou necessidade de
              ir ao banheiro, o guarda-volumes é uma ótima opção para guardar os
              itens com segurança.
            </p>
            <br />
            <p>
              Como os armários são modulares, é possível fazer instalação e
              adaptação nos mais variados locais.
            </p>
          </div>
        </div>
        <div className="lockers-description">
          <div className="lockers-description-text-2">
            <h2>Resistência e segurança sempre perto de você</h2>
            <p>
              São armários extremamente resistentes e totalmente invioláveis
              para oferecer segurança ao seu cliente e qualidade para o seu
              negócio.
            </p>
            <br />
            <p>
              Todos os armários são desenvolvidos em aço galvanizado, acabamento
              com tinta a pó e garantia de 3 anos, ou inoxidável com garantia de
              10 anos. Disponibilizamos mais de 16 cores com sistema exclusivo e
              patenteado de fechadura em aço inox, acionadas por fichas
              específicas de configuração exclusiva e controle de utilização
              através de numerador.
            </p>
            <br />
            <p>
              Um dos nossos diferenciais é a chave numerada impossível para
              duplicação, pois são cilindros com mais de 16 milhões de
              combinações. Também oferecemos fechaduras eletrônicas com sistema
              de codificação e pulseiras para chaves, utilizadas em clubes,
              parques aquáticos, escolas, além de fechaduras acionadas por
              fichas ou moedas.
            </p>
          </div>
          <img
            draggable="false"
            src="./digital-locker-2.jpg"
            alt="Armário digital Malex"
          />
        </div>
      </div>
      <div className="lockers-examples-section">
        <h2>Temos o modelo certo de armário para sua necessidade</h2>
        <div className="lockers-examples-row">
          <div className="lockers-example">
            <img
              draggable="false"
              src="/locker-traditional.png"
              alt="Armário tradicional Malex"
            />
            <h3>Armários tradicionais</h3>
            <p>
              Modelos tradicionais com chave são excelente para clientes de
              Aeroportos, Rodoviárias e Eventos
            </p>
          </div>
          <div className="lockers-example">
            <img
              draggable="false"
              src="/locker-digital.png"
              alt="Armário tradicional Malex"
            />
            <h3>Armários inteligentes</h3>
            <p>
              Indicado para clientes de shopping centers, os armários
              inteligentes disponibilizam espaço, um bom acabamento e segurança.
            </p>
          </div>
        </div>
      </div>
      <div className="lockers-photo-section">
        <img
          draggable="false"
          src="./lockers-event.jpg"
          alt="Malex funcionando em um evento"
        />
      </div>
    </div>
  );
};

export default LockersContent;
