import React from 'react';
import {
  AltNavbar,
  ContactSection,
  DHLContent,
  Footer,
  HeroDHL,
  NavbarMobile,
} from '../components';
import { isMobile } from 'react-device-detect';

const DHL = () => {
  return (
    <>
      {isMobile ? <NavbarMobile /> : <AltNavbar />}
      {isMobile ? <div style={{ paddingTop: 86.5 }} /> : <></>}
      <HeroDHL />
      <DHLContent />
      <ContactSection />
      <Footer />
    </>
  );
};

export default DHL;
