import React from 'react';
import {
  AltNavbar,
  Footer,
  HeroLocation,
  LocationContent,
  NavbarMobile,
} from '../components';
import { isMobile } from 'react-device-detect';

const Locations = () => {
  return (
    <>
      {isMobile ? <NavbarMobile /> : <AltNavbar />}
      {isMobile ? <div style={{ paddingTop: 86.5 }} /> : <></>}
      <HeroLocation />
      <LocationContent />
      <Footer />
    </>
  );
};

export default Locations;
