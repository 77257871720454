import React from 'react';
import './HeroDHL.css';

const HeroDHL = () => {
  return (
    <div className="hero-dhl">
      <h2>Malex e DHL conectando você com o mundo</h2>
    </div>
  );
};

export default HeroDHL;
