import React from "react";
import { isMobile } from "react-device-detect";

import {
  ContactSection,
  DescriptionSection,
  Footer,
  HeroHome,
  LocationSection,
  Navbar,
  NavbarMobile,
  PartnersSection,
  ServiceSection,
} from "../components";

const Home = () => {
  return (
    <>
      {isMobile ? <NavbarMobile /> : <Navbar />}
      <HeroHome />
      <DescriptionSection />
      <ServiceSection />
      <LocationSection />
      <PartnersSection />
      <ContactSection />
      <Footer />
    </>
  );
};

export default Home;
