import React from "react";
import "./HeroServices.css";

const HeroServices = () => {
  return (
    <div className="hero-services">
      <h2>Conheça nossos diferenciais e serviços</h2>
      <p>
        Com uma longa jornada no mercado, a Malex traz muito mais que
        praticidade, proporcionamos qualidade e segurança em cada detalhe dos
        nossos serviços.
      </p>
    </div>
  );
};

export default HeroServices;
