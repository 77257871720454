import React from 'react';
import {
  AltNavbar,
  ContactSection,
  Footer,
  HeroServices,
  NavbarMobile,
  ServicesContent,
} from '../components';
import { isMobile } from 'react-device-detect';

const Services = () => {
  return (
    <>
      {isMobile ? <NavbarMobile /> : <AltNavbar />}
      {isMobile ? <div style={{ paddingTop: 86.5 }} /> : <></>}
      <HeroServices />
      <ServicesContent />
      <ContactSection />
      <Footer />
    </>
  );
};

export default Services;
