import React from "react";
import "./App.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import {
  About,
  Contact,
  DHL,
  Home,
  Locations,
  Lockers,
  Services,
} from "./pages";
import ScrollToTop from "./utils/useScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/DHL" element={<DHL />} />
        <Route exact index path="/" element={<Home />} />
        <Route path="/locations" element={<Locations />} />
        <Route path="/lockers" element={<Lockers />} />
        <Route path="/services" element={<Services />} />
      </Routes>
    </Router>
  );
}

export default App;
