import React from "react";
import "./HeroAbout.css";

const HeroAbout = () => {
  return (
    <div className="hero-about">
      <h2>Um conceito inovador e prático</h2>
      <p>
        Saiba como a Malex proporciona novas experiências para diversos negócios
        do mundo inteiro.
      </p>
    </div>
  );
};

export default HeroAbout;
